import React, { ReactElement } from "react";
import styles from "./style.module.scss";
import UnmatchedComfort from "./icons/unmatchedComfort";
import EnhancedAppearance from "./icons/enhancedAppearance";
import ImprovedSafety from "./icons/improvedSafety";

const benefitsList = [
    {
        title: "Unmatched Comfort",
        description:
            "Aligners grant patients the freedom to live a normal life while wearing removable trays. Whether it's sleeping, eating, or practicing oral hygiene, the convenience of removing the trays as needed ensures a comfortable experience.",
        icon: <UnmatchedComfort />,
    },
    {
        title: "Enhanced Appearance",
        description:
            "The introduction of clear trays has revolutionized the perception of braces, offering the advantage of wearing them discreetly and removing them at any given time. Say goodbye to self-consciousness and hello to a confident smile.",
        icon: <EnhancedAppearance />,
    },
    {
        title: "Improved Safety",
        description:
            "Unlike traditional braces, which can cause discomfort and oral sores, aligners provide a safer alternative. The absence of brackets eliminates the possibility of food particles becoming trapped, lowering the risk of gum disease and decay brought on by bacterial growth.",
        icon: <ImprovedSafety />,
    },
];

export default function WhyUs(): ReactElement {
    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    return (
        <section className=" mt-12  lg:mb-24 mb-12">
            <div className="flex flex-col lg:flex-row justify-center gap-7 ">
                {benefitsList.map((el, index) => (
                    <div
                        key={index}
                        className={`sm:w-[350px] w-[300px] lg:mx-0  mx-auto     lg:h-[448px] h-[470px] flex flex-col items-center px-6 relative ${styles["shadowStyle"]}`}
                    >
                        <span className="mt-9 block">{el.icon}</span>
                        <h3 className="mt-7 font-bold lg:text-lg text-base text-[#4F9DCC]">
                            {el.title}
                        </h3>
                        <p className="text-center mt-6 min-h-[196px] font-light  lg:leading-6">
                            {el.description}
                        </p>
                        <span className="w-[133px] h-[2px] bg-[#3D91BE] block absolute bottom-7"></span>
                    </div>
                ))}
            </div>
            <div className="text-center mt-20">
                <button
                    onClick={() => scrollToTop()}
                    className={` py-4 sm:px-24 px-12 sm:text-lg font-medium  ${styles["buttonStyle"]}`}
                >
                    Get My Free Quote Now
                </button>
            </div>
        </section>
    );
}
