import React from "react";

function EnhancedAppearance() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            fill="none"
            viewBox="0 0 60 60"
        >
            <mask
                id="mask0_1_77"
                style={{ maskType: "alpha" }}
                width="60"
                height="60"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H60V60H0z"></path>
            </mask>
            <g mask="url(#mask0_1_77)">
                <path
                    fill="#4F9DCC"
                    d="M51 22.09a21.11 21.11 0 00-5.632-14.378 20.971 20.971 0 00-13.891-6.66A20.949 20.949 0 0016.79 5.691a21.091 21.091 0 00-7.586 13.443 21.132 21.132 0 003.59 15.024 20.996 20.996 0 0012.837 8.53l-1.68 10.143a6.17 6.17 0 001.368 4.991A6.123 6.123 0 0030 60a6.124 6.124 0 004.68-2.178 6.17 6.17 0 001.368-4.99l-1.68-10.173A21.018 21.018 0 0046.3 35.32 21.15 21.15 0 0051 22.091zM31.623 43.166l1.68 10.144a3.323 3.323 0 01-.756 2.81 3.466 3.466 0 01-5.095 0 3.323 3.323 0 01-.757-2.81l1.68-10.144h3.248zM30 40.355a18.166 18.166 0 01-12.87-5.35 18.296 18.296 0 01-5.33-12.914c0-4.844 1.917-9.49 5.33-12.915A18.167 18.167 0 0130 3.826c4.826 0 9.456 1.924 12.868 5.35A18.297 18.297 0 0148.2 22.09a18.327 18.327 0 01-5.338 12.907 18.196 18.196 0 01-12.862 5.358zm0-33.719a15.372 15.372 0 00-10.89 4.527A15.483 15.483 0 0014.6 22.09c0 4.099 1.622 8.03 4.51 10.927A15.373 15.373 0 0030 37.545c4.084 0 8.001-1.628 10.89-4.527a15.482 15.482 0 004.51-10.927 15.51 15.51 0 00-4.52-10.92A15.4 15.4 0 0030 6.636zm0 28.1a12.58 12.58 0 01-8.91-3.704 12.668 12.668 0 01-3.69-8.941c0-3.354 1.327-6.57 3.69-8.941A12.579 12.579 0 0130 9.446c3.341 0 6.546 1.333 8.91 3.704a12.668 12.668 0 013.69 8.94 12.695 12.695 0 01-3.7 8.934 12.608 12.608 0 01-8.9 3.712zM28.6 53c0-.568.34-1.08.864-1.298a1.396 1.396 0 011.525.305c.4.401.52 1.006.304 1.53A1.4 1.4 0 0128.6 53zm5.18-37.512a1.35 1.35 0 010 1.966l-8.4 8.43a1.34 1.34 0 01-1.96 0 1.351 1.351 0 010-1.967l8.4-8.43a1.343 1.343 0 011.96 0zm0 8.43a1.35 1.35 0 010 1.966l-2.8 2.81a1.342 1.342 0 01-1.96 0 1.352 1.352 0 010-1.967l2.8-2.81a1.343 1.343 0 011.96 0z"
                ></path>
            </g>
        </svg>
    );
}

export default EnhancedAppearance;
