import React from "react";

function UnmatchedComfort() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            fill="none"
            viewBox="0 0 60 60"
        >
            <mask
                id="mask0_1_85"
                style={{ maskType: "alpha" }}
                width="60"
                height="60"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H60V60H0z"></path>
            </mask>
            <g mask="url(#mask0_1_85)">
                <path
                    fill="#4F9DCC"
                    d="M53.317 29.774a5.23 5.23 0 00.986-3.07c-.037-2.902-2.351-5.178-5.272-5.178H36.612V10.447C36.608 7.443 34.12 5 31.06 5c-2.9 0-5.248 2.12-5.827 5.197-.525 2.011-1.974 7.463-2.357 8.549-.638 1.8-1.963 3.997-3.262 4.719-1.08.602-3.99 1.74-6.065 2.52v-.913c0-.67-.55-1.214-1.227-1.214H4.227c-.677 0-1.227.544-1.227 1.214V52.44c0 .67.55 1.214 1.227 1.214h8.095c.678 0 1.227-.544 1.227-1.214v-.69c1.19.602 2.646 1.348 3.306 1.707l3.672 2.349c.199.128.43.194.668.194H46.33c2.908 0 5.274-2.341 5.274-5.216a5.155 5.155 0 00-.984-3.033c2.133-.668 3.682-2.647 3.682-4.977a5.155 5.155 0 00-.984-3.033A5.263 5.263 0 0057 34.717c-.035-2.352-1.557-4.292-3.682-4.943h-.001zM11.095 51.223h-5.64v-24.94h5.64v24.94zm40.634-13.67h-2.7c-.678 0-1.227.544-1.227 1.214 0 .67.55 1.214 1.227 1.214 1.554 0 2.818 1.253 2.818 2.79 0 1.538-1.264 2.788-2.818 2.788h-2.7c-.678 0-1.227.545-1.227 1.215s.55 1.214 1.227 1.214c1.556 0 2.82 1.253 2.82 2.79 0 1.538-1.267 2.788-2.82 2.788H21.556l-3.436-2.193c-.96-.53-3.385-1.758-4.57-2.355V28.576c1.737-.643 5.77-2.166 7.265-2.999 2.055-1.139 3.64-3.96 4.374-6.03.498-1.404 2.34-8.446 2.435-8.825.383-1.999 1.73-3.294 3.436-3.294 1.705 0 3.095 1.356 3.095 3.02v12.218l-.003.075c0 .67.55 1.214 1.227 1.214h13.65c1.561 0 2.8 1.22 2.818 2.79 0 1.54-1.264 2.791-2.818 2.791-.677 0-1.227.544-1.227 1.214 0 .67.55 1.215 1.227 1.215h2.7a2.795 2.795 0 012.815 2.788c0 1.547-1.263 2.8-2.814 2.8h-.001z"
                ></path>
            </g>
        </svg>
    );
}

export default UnmatchedComfort;
