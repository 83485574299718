import React from "react";

function ImprovedSafety() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            fill="none"
            viewBox="0 0 60 60"
        >
            <mask
                id="mask0_1_80"
                style={{ maskType: "alpha" }}
                width="60"
                height="60"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H60V60H0z"></path>
            </mask>
            <g
                fill="#4F9DCC"
                fillRule="evenodd"
                clipRule="evenodd"
                mask="url(#mask0_1_80)"
            >
                <path d="M50.682 12.558a3.238 3.238 0 00-2.453-3.142L30.953 5.097a3.23 3.23 0 00-1.572 0l-17.276 4.32a3.238 3.238 0 00-2.453 3.141v21.595c0 6.214 3.255 10.856 7.275 14.13 5.525 4.5 12.431 6.282 12.431 6.282.531.138 1.088.138 1.619 0 0 0 6.906-1.782 12.43-6.282 4.02-3.274 7.276-7.916 7.276-14.13V12.558zm-2.16 0v21.595c0 5.49-2.926 9.563-6.478 12.456-5.154 4.198-11.597 5.863-11.607 5.865a1.058 1.058 0 01-.54 0c-.01-.002-6.451-1.667-11.606-5.865-3.552-2.892-6.479-6.966-6.479-12.456V12.558c0-.496.337-.927.818-1.047l17.275-4.319c.172-.043.352-.043.524 0l17.275 4.319c.481.12.818.551.818 1.047zm-2.159 1.687c0-.496-.337-.928-.817-1.049L30.43 9.417a1.108 1.108 0 00-.524 0l-15.116 3.78c-.481.12-.818.552-.818 1.048v19.908c0 4.765 2.601 8.27 5.683 10.78 3.904 3.18 8.602 4.797 10.2 5.282.204.062.423.062.628 0 1.595-.485 6.294-2.102 10.199-5.281 3.082-2.51 5.682-6.017 5.682-10.781V14.245zm-2.159.842v19.066c0 4.041-2.274 6.977-4.887 9.107-3.392 2.762-7.433 4.245-9.148 4.79-1.718-.545-5.759-2.028-9.15-4.79-2.614-2.13-4.888-5.066-4.888-9.107V15.087l14.037-3.51 14.036 3.51z"></path>
                <path d="M21.398 32.124l4.32 4.319a3.237 3.237 0 004.58 0l8.638-8.638a3.24 3.24 0 00-4.58-4.58l-6.348 6.347-2.03-2.028a3.24 3.24 0 00-4.58 4.58zm1.527-1.526a1.081 1.081 0 010-1.527 1.081 1.081 0 011.527 0l2.792 2.792a1.08 1.08 0 001.527 0l7.11-7.111a1.08 1.08 0 011.528 1.527l-8.638 8.637a1.078 1.078 0 01-1.527 0l-4.319-4.318z"></path>
            </g>
        </svg>
    );
}

export default ImprovedSafety;
