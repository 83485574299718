import React, { ReactElement } from "react";

export default function Advantages(): ReactElement {
    return (
        <section className=" mt-24 sm:text-center max-w-[1080px] mx-auto">
            <h2 className="text-[#4F9DCC] lg:text-2xl text-lg lg:leading-9 leading-7 font-bold">
                What Are the Best Teeth Aligners?
            </h2>
            <p className="mt-4 font-light">
                The best aligners vary depending on individual needs and
                preferences. Some popular options include Invisalign,
                ClearCorrect, and SmileDirectClub. It's important to consult
                with a dental professional to determine the best option for your
                specific case.
            </p>
            ca
            <h2 className="text-[#4F9DCC] lg:text-2xl text-lg lg:leading-9 leading-7 font-bold mt-14">
                What Are the Advantages of Wearing Aligners?
            </h2>
            <p className="mt-4 font-light">
                The field of dentistry has undergone a remarkable
                transformation, revolutionizing both patient perception and
                traditional techniques. Invisible braces or aligners have
                emerged as a game-changer among the various options available.
                With aligners, patients can now easily embrace orthodontic
                treatment, bidding farewell to any associated pain.
            </p>
        </section>
    );
}
